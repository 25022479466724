import config from "@/config";
import { handleResponse, handleErrors } from "./api-helper";
import authHeader from "./auth-header";

class AuthService {
  baseUrl = config.apiUrl;
  getUrl(path) {
    return `${this.baseUrl}/${path}`;
  }

  async login(username, password) {
    return this.post(this.getUrl("api/login"), {
      username,
      password,
    }).then((response) => {
      if (response.token) {
        window.sessionStorage.setItem("user", JSON.stringify(response));
      }

      return response.data;
    });
  }

  async refreshToken() {
    let user = JSON.parse(window.sessionStorage.getItem("user"));
    if (user && user.refresh_token) {
      try {
        return this.post(this.getUrl("api/token/refresh"), {
          refresh_token: user.refresh_token,
        }).then((response) => {
          if (response.token) {
            window.sessionStorage.setItem("user", JSON.stringify(response));
          }

          window.location.reload();
        });
      } catch (err) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  async getUser() {
    return this.get(this.getUrl("api/me"));
  }

  async post(url, data = {}) {
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }

  async get(url) {
    try {
      if (!url) throw Error("url is not provided");
      let options = {
        headers: authHeader(),
      };
      const response = await fetch(url, options);
      return handleResponse(response);
    } catch (err) {
      handleErrors(err);
    }
  }

  logout() {
    window.sessionStorage.removeItem("user");
    window.location = "/";
  }
}

export default new AuthService();
