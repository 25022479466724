import auth from "@/services/auth";

async function handleResponse(response) {
  if (!response.ok) {
    return handleErrors(response);
  }

  return response.json();
}

async function handleErrors(response) {
  console.error(response)
  let message = "Something went wrong :(";
  if (typeof response.json === "function") {
    const data = await response.json();
    if (response.status === 422) {
      message = data.validationError;
    } else if (response.status === 401) {
      if (data.message === "Expired JWT Token") {
        auth.refreshToken();
        return;
      }
      message = data.message;
    } else {
      message = data.error;
    }
  }
  return Promise.reject(message);
}

export { handleErrors, handleResponse };
