const messages = {
  en: {
    "Your application has been received": "We received your application",
  },
  lt: {
    "Your application has been received": "Jūsų paraiška gauta",
    "Basic details": "Pagrindinė informacija",
    "View and edit your personal details": "Peržiūrėti ir redaguoti asmeninę informaciją",
    "First name": "Vardas",
    "Last name": "Pavardė",
    "Email": "El. paštas",
    "Save": "Išsaugoti",
    "Payment methods": "Mokėjimo metodai",
    "Coming soon": "Jau netrukus",
    "Performance overview": "Veiklos apžvalga",
    "Daily": "Paros",
    "Weekly": "Savaitės",
    "Start date": "Pradžios data",
    "End date": "Pabaigos data",
    "Load": "Įkelti",
    "Sales": "Pardavimai",
    "Ad Spend": "Reklamos išlaidos",
    "Customers": "Klientai",
    "New Customers": "Nauji klientai",
    "ROAS": "ROAS",
    "CPA": "CPA",
    "Transactions": "Sandoriai",
    "CPO": "CPO",
    "This year": "Šie metai",
    "Last year": "Praėję metai",
    "vs last year": "palyginus su praėjusiais metais",
    "Business performance": "Verslo veikla",
    "% of revenue you wish to allocate for repayment": "Pajamų % skirtas grąžinimui",
    "Maximum Loan Amount": "Maksimali paskolos suma",
    "Number cannot be lower than 0": "Skaičius negali būti mažesnis nei 0",
    "Number cannot be greater than 99999999999": "Skaičius negali būti didesnis nei 99999999999",
    "Complete this field": "Užpildykite šį lauką",
    "Connect Google Tools": "Prisijunkite prie „Google“ įrankių",
    "Connect Facebook Business": "Prisijunkite prie „Facebook Business“",
    "Google login was interrupted": "„Google“ prisijungimas buvo nutrauktas",
    "Could not retrieve your assets, make sure all of the permissions have been granted": "Nepavyko gauti duomenų, įsitikinkite, kad visi leidimai suteikti",
    "Facebook login was interrupted": "„Facebook“ prisijungimas buvo nutrauktas",
    "Connect Woocommerce Store": "Prisijunkite prie Woocommerce parduotuvės",
    "Connect Shopify Store": "Prisijunkite prie Shopify parduotuvės",
    "Connect your Shopify or Woocommerce store": "Prisijunkite prie savo Shopify arba Woocommerce parduotuvės",
    "Last step": "Paskutinis žingsnis",
    "Business email": "Verslo el. paštas",
    "Password": "Slaptažodis",
    "Repeat password": "Pakartokite slaptažodį",
    "Back": "Atgal",
    "Next": "Kitas",
    "Register": "Registruotis",
    "Confirm your email": "Patvirtinkite savo el. paštą",
    "E-mail is required": "Reikalingas el. paštas",
    "E-mail must be valid": "El. paštas turi būti veikiantis",
    "Password is required": "Reikalingas slaptažodis",
    "Min 8 characters": "Mažiausiai 8 simboliai",
    "The passwords do not match": "Slaptažodžiai nesutampa",
    "Loan offer": "Paskolos pasiūlymas",
    "Based on your sales, your real 3 months average revenue is": "Remiantis jūsų pardavimais, jūsų realios 3 mėnesių vidutinės pajamos yra",
    "We've adjusted the maximum available loan amount based on your performance": "Mes pakoregavome maksimalią galimą paskolos sumą remiantis jūsų veiklos rezultatais",
    "Adjust your loan amount": "Pakoreguoti savo paskolos sumą",
    "Adjust the % of revenue you wish to allocate for repayment": "Pakoreguoti % pajamų, kurį norite skirti grąžinimui",
    "Get offer": "Gauti pasiūlymą",
    "Loan amount": "Paskolos suma",
    "Fixed fee": "Fiksuotas mokestis",
    "Revenue share for debt repayments": "Pajamų dalis skolos grąžinimui",
    "Select offer": "Pasirinkti pasiūlymą",
    "Get a new offer": "Gauti naują pasiūlymą",
    "No loan offers found": "Nerasta paskolos pasiūlymų",
    "Based on your sales performance, we could not find suitable loans at this time": "Pagal jūsų pardavimų veiklą šiuo metu tinkamų paskolų pasiūlymų nėra",
    "% of average gross margin on products": "Produktų vidutinės bendrosios maržos procentas",
    "Gross Margin % = (Revenue - Cost of goods) / Revenue": "Bendrosios maržos procentas = (Pajamos - Prekių sąnaudos) / Pajamos",
    "Average fulfilment cost per order": "Vidutinė užsakymo vykdymo išlaidų suma",
    "How much you pay on average for packaging and delivery": "Vidutinė išlaidų už supakavimą ir pristatymą suma",
    "Value should be a number": "Reikšmė turi būti skaičius",
    "Business info": "Verslo informacija",
    "Ecommerce site address": "El. parduotuvės svetainės adresas",
    "Business email": "Verslo el. paštas",
    "eCommerce Platform": "El. prekybos platforma",
    "eCommerce Platform is required": "Reikalinga el. prekybos platforma",
    "Referral Code": "Rekomendacijos kodas",
    "You must agree to continue!": "Norėdami tęsti, turite sutikti!",
    "Yes, I agree with the": "Taip, aš sutinku su",
    "privacy policy": "privatumo politika",
    "and the": "ir su",
    "terms and conditions": "sąlygomis",
    "Invalid domain": "Neteisingas domenas",
    "Website is required": "Reikalingas svetainės adresas",
    "Insights": "Įžvalgos",
    "Create account": "Sukurti paskyrą",
    "Ad accounts": "Reklamos paskyros",
    "Connect": "Prisijungti",
    "Connect your Shopify store": "Prisijunkite prie savo „Shopify“ parduotuvės",
    "Must end with '.myshopify.com'": "Turi baigtis „.myshopify.com“",
    "Before connecting, ensure your Wordpress permalink structure is not using the 'Plain' option": "Prieš prisijungdami, įsitikinkite, kad jūsų „WordPress“ nuorodos struktūra nenaudoja „Plain“ parinkties",
    "Woocommerce store URL": "WooCommerce parduotuvės adresas",
    "Consumer key": "Vartotojo raktas",
    "Consumer secret": "Vartotojo paslaptis",
    "Or enter your API key manually": "Arba įveskite savo API raktą rankiniu būdu",
    "Generate an API key following the guide in this": "Sugeneruokite API raktą, vadovaujantis šia",
    "link": "nuoroda",
    'In the field "Permissions", select the "Read" option': 'Laukelyje Permissions“ pasirinkite "Read" parinktį',
    "Or use the automatic Auth flow": "Arba naudokite automatinę autentifikacijos seką",
    "Cancel": "Atšaukti",
    "Enter a valid URL": "Įveskite veikiantį URL",
    "Enter a valid consumer key": "Įveskite galiojantį vartotojo raktą",
    "Enter a valid consumer secret": "Įveskite galiojančią vartotojo paslaptį",
    "Connect your Google assets": "Prisijunkite prie savo Google įrankių",
    "Analytics account": "Analytics paskyra",
    "Search Console site": "Search Console svetainė",
    "Ads account": "Reklamos paskyra",
    "Seems you don't have any assets on Google": "Atrodo, kad neturite jokių Google įrankių",
    "Connect your Facebook assets": "Prisijunkite prie savo „Facebook“ įrankių",
    "How to connect your Ad Account": "Kaip prisijungti prie jūsų reklamos paskyros",
    "Select business assets": "Pasirinkite verslo įrankius",
    "Go to Meta Ad accounts management following this": "Eikite į „Meta Ad“ paskyrų valdymą, vadovaudamiesi šia",
    "Add ecomfund. as your partner by clicking on “Assign partners”": "Pridėkite ecomfund. kaip savo partnerį, paspaudę „Assign partners“",
    "In the field “Partner business ID” enter": "Laukelyje „Partner business ID“ įveskite",
    "Turn on “Manage ad accounts” and click “Next”": "Įjunkite „Manage ad accounts“ ir spustelėkite „Next“",
    "How to connect your Pixel": "Kaip prisijungti prie jūsų Pixel",
    "Seems you don't have any businesses setup on Facebook": "Atrodo, kad „Facebook“ neturite jokių įmonių profilių",
    "Business manager": "Business manager",
    "Ad account": "Reklamos paskyra",
    "Turn on “Manage Pixels” and click “Next”": "Įjunkite „Manage Pixelsl“ ir spustelėkite Next“",
    "Product catalog": "Produktų katalogas",
    "Login": "Prisijungti",
    "Business not found": "Verslas nerastas",
    "Unlock the power of your potential! Connect your social account today and gain valuable insights that will propel your brand to new heights.": "Atskleiskite savo potencialo galią! Prijunkite savo socialines paskyras šiandien ir gaukite vertingų įžvalgų, kurios pakylės jūsų prekės ženklą į naujas aukštumas",
    "Connect some integrations in order to see a detailed performance dashboard": "Prijunkite integracijas, kad galėtumėte matyti išsamią veiklos suvestinę",
    "Your business data is being fetched at the moment. You will be sent an email notification to let you know when your performance dashboard is ready": "Jūsų verslo duomenys šiuo metu yra renkami. Kai jūsų veiklos suvestinė bus paruošta, informuosime jus el. laišku",
    "Your target KPI's": "Jūsų tiksliniai KPI",
    "Based on the provided information: a": "Remiantis pateikta informacija:",
    "average gross margin, an average fulfillment cost of": "vidutinė bendroji marža, vidutinės vykdymo išlaidos",
    "per order, and an average order value of": "užsakymui ir vidutinė užsakymo vertė",
    "Average revenue for the last 3 months": "Vidutinės pajamos per paskutinius 3 mėnesius"
  }
}

export default messages