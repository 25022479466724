import { createApp } from "./main";
import Notifications from "@kyvg/vue3-notification";
import mixins from "@/mixins";

const { app, router } = createApp();

router.isReady().then(() => {
  app.use(Notifications);
  app.mixin(mixins);
  app.mount("#app");
});
