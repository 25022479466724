<template>
  <div>
    <div
      v-if="loading"
      class="loading-overlay"
      style="
        z-index: 9999;
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
      "
    >
      <div v-if="appConfig">
        <v-avatar
          class="mx-auto mb-6 spinner"
          size="64"
          color="secondary"
          rounded="circle"
          :style="{ 'background-image': 'url(' + appConfig.logoUrl + ')' }"
        ></v-avatar>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import config from "@/config";
import { useSeoMeta } from "unhead";
import { useRoute } from "vue-router";
import { useAppStore } from "@/store/app";
import { mapActions, mapState } from "pinia";
import api from "@/services/api";

const initSession = async () => {
  if (window.sessionStorage.getItem("lead-uuid")) {
    return;
  }
  await api.lead.post().then((resp) => {
    window.sessionStorage.setItem("lead-uuid", resp.uuid);
  }); //.catch((err) => {console.log('catch' + err)});
};
const appStore = useAppStore();
const loading = computed(() => {
  return appStore.loading;
});
onMounted(() => {
  appStore.setLoading(false);
  initSession();
});
const appConfig = computed(() => {
  return config;
});

const route = useRoute();
// useServerSeoMeta({
//   title: config.title,
//   description: config.description,
//   ogDescription: config.description,
//   ogTitle: config.title,
//   ogImage: config.appUrl + '/img/banner/gamekeys.jpg',
//   twitterCard: 'summary_large_image',
// })
if (route.name === "Home") {
  useSeoMeta(config.seo);
}

// const head = {
//   title: config.title,
//   meta: [
//     {
//       name: 'description',
//       content: config.description
//     },
//     {
//       name: 'og:description',
//       content: config.description
//     },
//     {
//       name: 'og:title',
//       content: config.title
//     },
//     {
//       name: 'og:image',
//       content: config.appUrl + "/img/banner/gamekeys.jpg"
//     },
//     {
//       name: 'fb:app_id',
//       content: config.fbAppId
//     },
//     {
//       name: 'og:title',
//       content: config.title
//     },
//   ]
// }
// useServerHead(head)
// useHead(head)
</script>

<style scoped>
.spinner {
  width: 54px;
  height: 54px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/favicon.ico");
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
