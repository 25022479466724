export default {
  title:
    import.meta.env.VITE_APP_TITLE ||
    import.meta.env.VITE_APP_URL ||
    "https://app.local.dev",
  logoUrl:
    import.meta.env.VITE_APP_LOGO_URL ||
    "https://etrueno.local.dev/images/logo.png",
  description: import.meta.env.VITE_APP_DESCRIPTION || "Description",
  bcp47LanguageTag: import.meta.env.VITE_BCP47_LANGUAGE_TAG || "en-US",
  recaptchaSiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY || "",
  appType: import.meta.env.VITE_APP_TYPE || "site",
  apiUrl: import.meta.env.VITE_API_URL || "https://api.local.dev",
  appUrl: import.meta.env.VITE_APP_URL || "https://app.local.dev",
  privacyLink:
    import.meta.env.VITE_APP_PRIVACY_LINK ||
    import.meta.env.VITE_APP_URL + "/privacy-policy/",
  termsLink:
    import.meta.env.VITE_APP_TERMS_LINK ||
    import.meta.env.VITE_APP_URL + "/terms-conditions/",
  googleApiClientId: import.meta.env.VITE_GOOGLE_API_CLIENT_ID || "",
  themes: {
    light: {
      colors: {
        primary:
          import.meta.env.VITE_APP_THEMES_LIGHT_PRIMARY_COLOR || "#f69009",
        secondary:
          import.meta.env.VITE_APP_THEMES_LIGHT_SECONDARY_COLOR || "#333333",
        accent: import.meta.env.VITE_APP_THEMES_LIGHT_ACCENT_COLOR || "#7d1652",
        error: import.meta.env.VITE_APP_THEMES_LIGHT_ERROR_COLOR || "#FF5252",
        info: import.meta.env.VITE_APP_THEMES_LIGHT_INFO_COLOR || "#2196F3",
        success:
          import.meta.env.VITE_APP_THEMES_LIGHT_SUCCESS_COLOR || "#4CAF50",
        warning:
          import.meta.env.VITE_APP_THEMES_LIGHT_WARNING_COLOR || "#f7a500",
      },
    },
  },
  maintenance: import.meta.env.VITE_MAINTENANCE === "true",
  loaderDelay: import.meta.env.VITE_LOADER_DELAY || "0",
  formSteps: import.meta.env.VITE_FORM_STEPS_CSV
    ? import.meta.env.VITE_FORM_STEPS_CSV.split(",")
    : [],
  formStepLabels: import.meta.env.VITE_FORM_STEP_LABELS_CSV
    ? import.meta.env.VITE_FORM_STEP_LABELS_CSV.split(",")
    : [],
  facebookBusinessId: import.meta.env.VITE_FB_BUSINESS_ID,
  facebookBusinessScopes: import.meta.env.VITE_FB_BUSINESS_SCOPES,
  logRocket: {
    enabled: import.meta.env.VITE_LOGROCKET_ENABLED === "true",
    id: import.meta.env.VITE_LOGROCKET_ID,
  },
  fbAppId: import.meta.env.VITE_FB_APP_ID || "0",
  seo: {
    title: import.meta.env.VITE_APP_TITLE || "somedomain.com",
    description: import.meta.env.VITE_APP_DESCRIPTION || "Some description",
    ogDescription:
      import.meta.env.VITE_APP_DESCRIPTION || "Some og description",
    ogTitle: import.meta.env.VITE_APP_TITLE || "somedomain.com og",
    ogImage:
      (import.meta.env.VITE_APP_URL || "https://somedomain.com") +
      "/img/logo.jpg",
    twitterCard: "summary_large_image",
    ogType: "website",
  },
  gTagId: import.meta.env.VITE_GTAG_ID || "G-PLJP8XXY0F",
  gtmId: import.meta.env.VITE_GTM_ID || "GTM-PQ2JDNSG",
};
