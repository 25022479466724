import moment from "moment";
export default {
  methods: {
    formatTimestamp: function (
      timestamp,
      format = "dddd, MMMM Do YYYY, h:mm:ss a"
    ) {
      return moment(timestamp).format(format);
    },
    formatFromNow: function (timestamp) {
      return moment(timestamp, "YYYY-MM-DD H:i:s").fromNow();
    },
    formatCurrency: function (val, currency = "EUR") {
      const locale = (navigator && navigator.language) ?? "en-US";
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(val);
    },
    formatNumber: function (val) {
      const locale = (navigator && navigator.language) ?? "en-US";
      return new Intl.NumberFormat(locale).format(val);
    },
    goTo: function (destination) {
      window.location = destination;
    },
  },
};
