export default function authHeader() {
  let user =
    typeof window !== "undefined"
      ? JSON.parse(window.sessionStorage.getItem("user"))
      : false;
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}
