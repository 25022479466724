import { defineStore } from 'pinia'

export const useAppStore = defineStore("app", {
  state: () => ({
    loading: true,
    initialized: false,
    error: "OMG error :(",
    completedLogins: [],
    completedIntegrations: [],
    stepperFooterText: null,
    stepValid: [],
    loadingCancelButton: false
  }),
  actions: {
    setLoading(val) {
      this.loading = val
    },
    setStepValid(index, isValid) {
      this.stepValid[index] = isValid
    }
  },
});
