// Components
import App from "./App.vue";
import config from "./config";
import CountryFlag from "vue-country-flag-next";

// Composables
import { createSSRApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createI18n } from "vue-i18n";
import { createHead } from "@unhead/vue";
import { createPinia } from "pinia";
import { createRouter } from "./router";
import { routes } from "vue-router/auto-routes";
import { DataLoaderPlugin } from "unplugin-vue-router/data-loaders";
// Plugins
import { registerPlugins } from "@/plugins";
//import Notifications from "@kyvg/vue3-notification";
import translations from "./locale/translations";
import storePlugins from "@/plugins/storePlugins";
import VueGtag from "vue-gtag";

if (config.logRocket && config.logRocket.enabled) {
  LogRocket.init(config.logRocket.id);
  // LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
  //     name: 'James Morrison',
  //     email: 'jamesmorrison@example.com',
  //     // Add your own custom user variables here, ie:
  //     subscriptionType: 'pro'
  // });
}
let locale = "";
if (typeof localStorage !== "undefined") {
  locale = localStorage.getItem("locale") ?? "en";
} else if (typeof sessionStorage !== "undefined") {
  locale = window.sessionStorage.getItem("locale") ?? "en";
} else {
  console.log("Web Storage is not supported in this environment.");
}

const i18n = createI18n({
  messages: translations,
  locale: locale,
  fallbackLocale: "en",
});

export function createApp() {
  const app = createSSRApp(App);
  const pinia = createPinia();
  pinia.use(storePlugins);
  const head = createHead();
  const router = createRouter({
    routes,
  });
  app.use(pinia);
  app.use(head);
  app.use(DataLoaderPlugin, { router });
  app.use(router);
  app.use(i18n);
  app.use(VueGtag, {
    config: { id: config.gTagId },
  });
  app.use(
    createGtm({
      id: config.gtmId,
    })
  );
  app.use(CountryFlag);
  registerPlugins(app);
  return { app, router, head };
}
